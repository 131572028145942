import layers from "./layers.json";
import Map, {Marker, ViewStateChangeEvent, NavigationControl, AttributionControl} from "react-map-gl/maplibre";
import maplibregl, {LngLatBoundsLike} from "maplibre-gl";
import {Protocol} from "pmtiles";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    Cluster,
    findCheapestPointOrClusters,
    findCheapestStationInCluster,
    Point,
    PointOrCluster
} from "../../utils/MapTypes";
import {MapStateStorage} from "./MapStateStorage";
import useSupercluster from "use-supercluster";
import {Station} from "../../utils/model/Station";
import GMapCluster from "./GMapCluster";
import GMapMarker from "./GMapMarker";
import {useParams} from "react-router-dom";
import {FuelType} from "../../utils/model/FuelType";
import GoogleMapReact from "google-map-react";
import "maplibre-gl/dist/maplibre-gl.css";
import {useRouter} from "../../context/RouterContext";
import {Location} from "../../utils/model/Location";
import Puller from "../station/components/Puller";
import {StationComponent} from "../station/StationComponent";
import {SwipeableDrawer, SwipeableDrawerProps} from "@mui/material";
import GeolocationEnabledDialog from "../station/components/GeolocationEnabledDialog";
import {IsWebviewStorage} from "./IsWebviewStorage";
import CenterOnMe from "./components/CenterOnMe";
import GMapUserMarker from "./GMapUserMarker";

export interface MapViewport {
    zoom: number;
    bounds: any;
    center: GoogleMapReact.Coords;
    viewportDate: Date;
}

const SWISS_ZOOM = 8;
const SWISS_CLUSTER_BOUNDS = [3.497052742187492, 44.392533837958695, 12.956281257812492, 49];
const SWISS_CENTER: GoogleMapReact.Coords = {lat: 46.801111, lng: 8.226667}
const DEFAULT_MAP_VIEWPORT: MapViewport = {
    zoom: SWISS_ZOOM,
    bounds: SWISS_CLUSTER_BOUNDS,
    center: SWISS_CENTER,
    viewportDate: new Date()
}
const DEFAULT_PANBY_Y_MARGIN = 120;
const MARKER_HEIGHT = 55;

const SWISS_BOUNDS: LngLatBoundsLike = [[2, 44], [15, 49]];
const ONCENTER_MIN_ZOOMLEVEL = 15;

const GEOLOCATION_OPTIONS = {
    enableHighAccuracy: true,
    timeout: 10000,
    maximumAge: 15000
};

const config = {
    host: "https://tcsmaps.ch",
    map: "planet",
};

export default function MMap({stationPoints}: { stationPoints: Point[] }) {
    const {fuelId, stationId} = useParams();
    const fuelType = fuelId as FuelType;
    const station = stationPoints.find((stationPoints) => stationPoints.properties.station.id === stationId)?.properties.station;

    const {navigate} = useRouter();
    const mapRef = useRef<any>();

    const isWebview = IsWebviewStorage.getIsWebview();

    const [currentMapViewport, setCurrentMapViewport] = useState<MapViewport>(() => {
        //If station is selected, center to station on initial render
        if (station) {
            const latestViewport = MapStateStorage.getLatestMapViewport();
            const viewport: MapViewport = {
                zoom: latestViewport?.zoom ?? ONCENTER_MIN_ZOOMLEVEL,
                center: station.location,
                bounds: [],
                viewportDate: new Date()
            }
            return viewport;
        }

        //If existing AND not too old (30 min), center to latest user viewport so that user can continue his browsing with a sense of continuity
        const latestViewport = MapStateStorage.getLatestMapViewport();
        if (latestViewport && MapStateStorage.isViewPortRecent(latestViewport)) {
            return latestViewport;
        }

        // If user already gave his permission successfully, center to latest known position of user
        const latestKnownGeolocalisedViewport = MapStateStorage.getLatestKnownPosition();
        const isError = MapStateStorage.isLatestGeolocationRequestAnError();
        if (latestKnownGeolocalisedViewport && isError === false) {
            return latestKnownGeolocalisedViewport;
        }

        //If user had a previous viewport, we display it. If everything fails we display the whole Switzerland
        return latestViewport ?? DEFAULT_MAP_VIEWPORT;
    });

    const [initialMapViewport] = useState<MapViewport>(currentMapViewport);

    const [userCenter, setUserCenter] = useState<GoogleMapReact.Coords | undefined>(() => {
        const isError = MapStateStorage.isLatestGeolocationRequestAnError()
        if (isError === true) { return undefined }

        return MapStateStorage.getLatestKnownPosition()?.center ?? undefined
    });
    const [isDetectingLocation, setIsDetectingLocation] = useState(false);
    const [geolocationError, setGeolocationError] = useState<GeolocationPositionError | null>(null);
    const [geolocationDialog, setGeolocationDialog] = useState<boolean>(true);

    const [canCenterToUserOnInitialLoadValue] = useState<boolean>(() => {
        if (station) {
            return false;
        }

        const latestViewport = MapStateStorage.getLatestMapViewport()
        if (latestViewport && MapStateStorage.isViewPortRecent(latestViewport)) {
            return false;
        }

        const isError = MapStateStorage.isLatestGeolocationRequestAnError()
        return isError === false;

    })
    const canCenterToUserOnInitialLoadRef = useRef<boolean>(canCenterToUserOnInitialLoadValue);

    let radius = 1;
    const currentZoom = currentMapViewport.zoom;
    if (currentZoom > 0 && currentZoom <= 10) {
        radius = 180
    } else if (currentZoom > 10 && currentZoom <= 13) {
        radius = 120
    } else if (currentZoom > 13) {
        radius = 30
    }

    const {clusters: rawPointsOrClusters, supercluster} = useSupercluster({
        // @ts-ignore
        points: stationPoints,
        bounds: currentMapViewport.bounds,
        zoom: currentMapViewport.zoom,
        options: {radius: radius, minZoom: 0, maxZoom: 14}
    });

    const pointOrClusters: PointOrCluster[] = rawPointsOrClusters.map(rawPointOrCluster => {
        // @ts-ignore
        const isCluster = rawPointOrCluster.properties.cluster === true;
        const cluster = isCluster ? rawPointOrCluster as Cluster : undefined;
        // @ts-ignore
        const point = isCluster ? undefined : rawPointOrCluster as Point;

        let station: Station | undefined = undefined;
        if (isCluster) {
            // @ts-ignore
            const leavesPoints = supercluster.getLeaves(rawPointOrCluster.id, Infinity);
            // @ts-ignore
            station = findCheapestStationInCluster(leavesPoints.map((point: Point) => point.properties.station), fuelType);
        } else {
            station = point?.properties.station;
        }

        const pointOrCluster: PointOrCluster = {
            isCluster: isCluster,
            isCheapest: false,
            cluster: cluster,
            point: point,
            station: station
        }
        return pointOrCluster;
    })

    const cheapestsPointOrCluster = findCheapestPointOrClusters(pointOrClusters, fuelType);
    for (const poc of cheapestsPointOrCluster) {
        poc.isCheapest = true;
    }

    const centerOnMe = useCallback(async (initialLoad: Boolean) => {
        //In a webview we disable all front-end checks
        if (!isWebview) {
            if (!navigator.geolocation) { return; }

            // For firefox and safari, if we don't check the "Remember This decision"
            // the permission will stay on "pending" and prompt on every page refresh.
            if (initialLoad && navigator.permissions) {
                const geolocationPermissions = await navigator.permissions.query({ name: 'geolocation' })
                if (geolocationPermissions.state !== 'granted') {
                    return
                }
            }
        }

        setIsDetectingLocation(true);
        navigator.geolocation.getCurrentPosition((position) => {
            const coords = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
            }
            const zoom = Math.max(currentMapViewport.zoom, ONCENTER_MIN_ZOOMLEVEL);

            if (mapRef.current
                && (!initialLoad || canCenterToUserOnInitialLoadRef.current)) {
                setTimeout(function () {
                    mapRef?.current?.setZoom(zoom);
                    mapRef?.current?.panTo(coords);
                }, 100);
            }
            setIsDetectingLocation(false);
            setGeolocationError(null);
            setUserCenter(coords);

            const viewport: MapViewport = {zoom: zoom, bounds: currentMapViewport.bounds, center: coords, viewportDate: new Date()};
            MapStateStorage.saveLatestKnownPosition(viewport);
            MapStateStorage.saveIsLatestGeolocationRequestAnError(false);
        }, (error) => {
            setIsDetectingLocation(false);
            setGeolocationError(error);
            setGeolocationDialog(true);
            MapStateStorage.saveIsLatestGeolocationRequestAnError(true);

        }, GEOLOCATION_OPTIONS);

    }, [currentMapViewport, isWebview])

    const zoomToSelectedStation = useCallback(() => {
        if (!mapRef.current) { return }
        if (!station) { return }

        let YMarginForPanBy = DEFAULT_PANBY_Y_MARGIN;
        const header = document.querySelector('.topBar') as HTMLElement | null;
        const bottomDrawer = document.querySelector('#drawerBlock') as HTMLElement | null;
        if (header != null && bottomDrawer != null) {
            const mapSizeViewport = window.innerHeight - header.offsetHeight;
            const mapCenter = mapSizeViewport / 2
            const drawerHeight = bottomDrawer.offsetHeight;
            const remainingHeight = mapSizeViewport - drawerHeight;
            const middleOfRemainingHeightFromDrawerTop = remainingHeight / 2;
            YMarginForPanBy = mapCenter - middleOfRemainingHeightFromDrawerTop - MARKER_HEIGHT - MARKER_HEIGHT / 2;
        }
        mapRef.current.flyTo({
            center: [station.location.lng, station.location.lat],
            duration: 500,
            offset: [0, -YMarginForPanBy]
        });

        canCenterToUserOnInitialLoadRef.current = false
    }, [mapRef, station])

    useEffect(() => {
        if (station) {
            zoomToSelectedStation()
        }
    }, [station, zoomToSelectedStation]);

    const onClusterClick = ({cluster}: { cluster: Cluster }) => {
        // @ts-ignore
        const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20);
        if (station) {
            navigate(`/map/${fuelId}`);
        }
        if (mapRef.current) {
            mapRef.current.easeTo({
                zoom: expansionZoom,
                center: [cluster.geometry.coordinates[0], cluster.geometry.coordinates[1]],
                duration: 500
            });
        }
    }

    const onStationClick = (station: Station) => {
        navigate(`/station/${station.id}/${fuelId}`);
    }

    const clickOutsideDrawer = () => {
        if (station) {
            navigate(`/map/${fuelId}`);
        }
    }

    const toggleDrawer = (open: boolean) => () => {
        if (!open) {
            navigate(`/map/${fuelId}`)
        }
    };

    useEffect(() => {
        let protocol = new Protocol();
        maplibregl.addProtocol("pmtiles", protocol.tile);
        return () => {
            maplibregl.removeProtocol("pmtiles");
        };
    }, []);

    const onMoveEnd = (event: ViewStateChangeEvent) => {
        const bounds = event.target.getBounds();
        const newBounds = [
            bounds.getSouthWest().lng,
            bounds.getSouthWest().lat,
            bounds.getNorthEast().lng,
            bounds.getNorthEast().lat
        ];

        const mMapCenter = event.target.getBounds().getCenter();
        let center: Location;
        if (mMapCenter) {
            center = {lat: mMapCenter.lat, lng: mMapCenter.lng};
        } else {
            const localZoomState = MapStateStorage.getLatestMapViewport();
            if (!localZoomState) {
                center = SWISS_CENTER;
            } else {
                center = localZoomState.center ?? SWISS_CENTER;
            }
        }

        const viewport: MapViewport = {
            zoom: Math.round(event.target.getZoom()),
            bounds: newBounds,
            center: center,
            viewportDate: new Date()
        };
        MapStateStorage.saveMapViewport(viewport);
        setCurrentMapViewport(viewport);
    }

    const onDragEnd = (() => {
        canCenterToUserOnInitialLoadRef.current = false;
    })

    const markers = useMemo(() => pointOrClusters.map(poc => {
            const [lng, lat] = poc.isCluster ? poc.cluster!.geometry.coordinates : poc.point!.geometry.coordinates
            if (poc.isCluster) {
                return <Marker key={poc.cluster!.id} latitude={lat} longitude={lng}>
                    <GMapCluster
                        lat={lat}
                        lng={lng}
                        cluster={poc.cluster!}
                        isCheapest={poc.isCheapest}
                        cheapestStation={poc.station}
                        fuelType={fuelType}
                        onClusterClick={onClusterClick}
                    />
                </Marker>
            } else {
                const active = poc.station?.id === station?.id;
                return <Marker style={{ zIndex: active ? 1 : "unset" }} key={poc.point!.properties.station.id} latitude={lat} longitude={lng}>
                    <GMapMarker
                        lat={lat}
                        lng={lng}
                        station={poc.point!.properties.station}
                        fuelType={fuelType}
                        isCheapest={poc.isCheapest}
                        onStationClick={onStationClick}
                        active={active}
                    />
                </Marker>
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [pointOrClusters]);

    const drawerBleeding = 56;
    const swipeableDrawerProps: SwipeableDrawerProps = {
        open: Boolean(station),
        onClose: toggleDrawer(false),
        onOpen: toggleDrawer(true),
        anchor: "bottom",
        swipeAreaWidth: drawerBleeding,
        disableSwipeToOpen: true,
        variant: "temporary",
        PaperProps: {
            sx: {
                display: 'flex'
            },
            id: "drawerBlock",
        },
        ModalProps: {
            keepMounted: true,
            sx: {
                top: window.innerHeight
            },
            BackdropProps: {
                invisible: true,
                onMouseDown: toggleDrawer(false),
                sx: {
                    top: window.innerHeight
                },
            }
        }
    }

    const onMapLoad = useCallback(()  => {
        const map = mapRef.current.getMap();
        map.keyboard.disableRotation();
        map.touchZoomRotate.disableRotation();
    }, []);

    return (
        <>
            {geolocationDialog && geolocationError &&
                <GeolocationEnabledDialog isGeolocationEnabled={false} onClose={() => setGeolocationDialog(false)}/>
            }
            <div className='google-map-inner'>
                <Map
                    ref={mapRef}
                    onDragEnd={onDragEnd}
                    onClick={clickOutsideDrawer}
                    onMoveEnd={onMoveEnd}
                    onLoad={onMapLoad}
                    touchPitch={false}
                    dragRotate={false}
                    touchZoomRotate={true}
                    minZoom={6}
                    maxBounds={SWISS_BOUNDS}
                    initialViewState={{
                        latitude: initialMapViewport.center.lat,
                        longitude: initialMapViewport.center.lng,
                        zoom: initialMapViewport.zoom,
                    }}
                    mapStyle={{
                        version: 8,
                        sources: {
                            protomaps: {
                                maxzoom: 15,
                                type: "vector",
                                tiles: [`${config.host}/${config.map}/{z}/{x}/{y}.mvt`],
                            },
                        },
                        glyphs: `${config.host}/fonts/{fontstack}/{range}.pbf`,
                        layers: [...(layers as any)],
                    }}
                    mapLib={maplibregl as any}
                >
                    {userCenter &&
                        <Marker latitude={userCenter.lat} longitude={userCenter.lng}>
                            <GMapUserMarker key='user-point' {...userCenter}/>
                        </Marker>
                    }
                    {markers}

                    <AttributionControl customAttribution="OpenStreetMap" />

                    <NavigationControl
                        showCompass={false}
                        position={'bottom-right'}
                    />
                </Map>
                {navigator.geolocation && (
                    <div className='center-on-me'>
                        <CenterOnMe onClick={() => {centerOnMe(false)}} loading={isDetectingLocation}/>
                    </div>
                )}
            </div>
            <SwipeableDrawer className="drawer" {...swipeableDrawerProps}>
                <Puller/>
                {station &&
                    <StationComponent station={station} drawerBleeding={drawerBleeding}/>
                }
            </SwipeableDrawer>
        </>
    );
}